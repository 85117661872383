<template>
  <div el="list md">
    <div el="list-column md">
      <div><input type="text" placeholder="Search..." class="searchInput" el></div>
      <div>
        <div el="btn" @click="downloadBillingHistory">Download</div>
      </div>
    </div>
    <div el="list xxsm" class="billingHistoryList">
        <div el="list-column-between p-xsm" v-for="item in transactions" class="transaction">
          <div el="list">
            <p el="p">{{ item.name }}</p>
            <p el="p" class="smallTxt">{{ item.createdOn }}</p>
          </div>  
          <div class="controls">
            <p el="p">${{ item.chargeAmount }}</p>
          </div>
        </div>
    </div>
  </div>
</template>
<script setup>
import { reactive } from 'vue';
import Papa from "papaparse";

let d = new Date();
let transactions = reactive([
  {
    name:'Rewards Plus Credit Card',
    issuer:'Bank of America',
    nickname:'BoA',
    createdOn: d,
    lastUpdated: d,
    expires: d,
    cardNumber:'4000123445671234',
    securityCode:'001',
    chargeFrequency:'monthly', // bi-weekly, monthly, quarterly, annually
    chargeAmount: 1.12, // $1
  },
  {
    name:'Money Back Credit Card',
    issuer:'Chase',
    nickname:'Chase Card',
    createdOn: d,
    lastUpdated: d,
    expires: d,
    cardNumber:'4000123445671234',
    securityCode:'001',
    chargeFrequency:'monthly', // bi-weekly, monthly, quarterly, annually
    chargeAmount: 1.09, // $1
  },
  {
    name:'Cash Back Credit Card',
    issuer:'Navy Federal',
    nickname:'Navy Fed',
    createdOn: d,
    lastUpdated: d,
    expires: d,
    cardNumber:'4000123445671234',
    securityCode:'001',
    chargeFrequency:'monthly', // bi-weekly, monthly, quarterly, annually
    chargeAmount: 1.09, // $1
  },
  {
    name:'Rewards Plus Credit Card',
    issuer:'Bank of America',
    nickname:'BoA',
    createdOn: d,
    lastUpdated: d,
    expires: d,
    cardNumber:'4000123445671234',
    securityCode:'001',
    chargeFrequency:'monthly', // bi-weekly, monthly, quarterly, annually
    chargeAmount: 1.12, // $1
  },
  {
    name:'Money Back Credit Card',
    issuer:'Chase',
    nickname:'Chase Card',
    createdOn: d,
    lastUpdated: d,
    expires: d,
    cardNumber:'4000123445671234',
    securityCode:'001',
    chargeFrequency:'monthly', // bi-weekly, monthly, quarterly, annually
    chargeAmount: 1.09, // $1
  },
  {
    name:'Cash Back Credit Card',
    issuer:'Navy Federal',
    nickname:'Navy Fed',
    createdOn: d,
    lastUpdated: d,
    expires: d,
    cardNumber:'4000123445671234',
    securityCode:'001',
    chargeFrequency:'monthly', // bi-weekly, monthly, quarterly, annually
    chargeAmount: 1.09, // $1
  },
  {
    name:'Rewards Plus Credit Card',
    issuer:'Bank of America',
    nickname:'BoA',
    createdOn: d,
    lastUpdated: d,
    expires: d,
    cardNumber:'4000123445671234',
    securityCode:'001',
    chargeFrequency:'monthly', // bi-weekly, monthly, quarterly, annually
    chargeAmount: 1.12, // $1
  },
  {
    name:'Money Back Credit Card',
    issuer:'Chase',
    nickname:'Chase Card',
    createdOn: d,
    lastUpdated: d,
    expires: d,
    cardNumber:'4000123445671234',
    securityCode:'001',
    chargeFrequency:'monthly', // bi-weekly, monthly, quarterly, annually
    chargeAmount: 1.09, // $1
  },
  {
    name:'Cash Back Credit Card',
    issuer:'Navy Federal',
    nickname:'Navy Fed',
    createdOn: d,
    lastUpdated: d,
    expires: d,
    cardNumber:'4000123445671234',
    securityCode:'001',
    chargeFrequency:'monthly', // bi-weekly, monthly, quarterly, annually
    chargeAmount: 1.09, // $1
  },
]);

const downloadBillingHistory = ()=>{
  let x = this;
  let data = transactions;
  let dataArr = [];
  for (const key in data) {
    let item = data[key];
    dataArr.push({
      Timestamp: item.createdOn,
      "Credit Card": item.name,
      Issuer: item.issuer,
      Amount: item.chargeAmount,
    });
  }
  let csvData = dataArr;
  let csv = Papa.unparse(csvData);
  // console.log(csv);
  downloadCSV("Billing History.csv", csv);
};


const downloadCSV = (filename, data) => {
  let blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
  let link = document.createElement("a");
  let url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", filename);
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

</script>
<style lang="scss">
.searchInput{
  padding:0px 16px;
  height:45px;
  border-radius:80px;
  --bg-color:white;
  box-shadow: 0 10px 20px hsla(0, 0%, 0%, 0.07);
}
.smallTxt{
  font-size:12px;
  font-style: italic;
}
.billingHistoryList{
  max-width:700px;
}
.transaction{
  border-radius:8px;
  --bg-color:white;
  box-shadow: 0 10px 20px hsla(0, 0%, 0%, 0.07);
}
.transaction{

}
</style>
